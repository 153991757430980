import { Model } from '@skywatch/core'
import { ApiBroker, ApiPostBroker } from '../../model'
import { BrokerType } from '../../forms/types/broker'

export interface Broker extends Model<number> {
  firstName: string
  lastName: string
  email: string
  phone?: string
  role: string
}

export const Broker = {
  toApi: (data: BrokerType): ApiPostBroker => {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
    }
  },
  fromApi: (data: ApiBroker): Broker => {
    return {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      role: data.role,
    }
  },
}
