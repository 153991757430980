import { useState } from 'react'
import { Alert, Button, SlashCircleIcon, useDialog } from '@skywatch/ui'
import { Wait, useErrorModal } from '@drive/shared'
import { BackendError } from '@skywatch/core'
import { Affiliate, AffiliateGrid } from '../store/types/affiliate'
import { useAffiliate } from '.'

type Props = {
  affiliate?: Affiliate
  affiliateGrid?: AffiliateGrid
}

export default ({ affiliate, affiliateGrid }: Props) => {
  const affiliateResource = useAffiliate()
  const { setOpen, Dialog, open } = useDialog()
  const [error, setError] = useState<BackendError>()
  const [loading, setLoading] = useState(false)
  const { setOpen: setOpenErrorModal, ErrorModal } = useErrorModal()

  const onDeleteAffiliate = async (affiliateId?: string) => {
    try {
      await affiliateResource.deactivateAffiliate(affiliateId)
      setOpen(false)
    } catch (e: any) {
      setOpen(false)
      setError({ message: e.messages[0].messages[0].message, title: e.messages[0].messages[0].payload })
      setOpenErrorModal(true)
    }
  }

  const DeleteAffiliatePopup = () => {
    return (
      <>
        <ErrorModal errorText={error?.message} errorTitle={error?.title} />
        <Dialog>
          <Alert icon={<SlashCircleIcon className="stroke-primary-500 stroke-2" />} onClose={() => setOpen(false)}>
            <div className="font-poppins mt-4">
              <div className="font-semibold">
                Are you sure you want to deactivate {affiliate?.name || affiliateGrid?.affiliateName} agency?
              </div>
              <div className="mt-8 grid grid-cols-2 gap-3">
                <Button
                  buttonType={'gray'}
                  variant={'outline'}
                  className="col-span-2 w-full md:col-span-1"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  buttonType={'primary'}
                  variant={'standard'}
                  className="col-span-2 w-full md:col-span-1"
                  onClick={async () => {
                    setLoading(true)
                    await onDeleteAffiliate(affiliate?.id || affiliateGrid?.id)
                    setLoading(false)
                  }}
                >
                  Deactivate Agency
                </Button>
              </div>
            </div>
          </Alert>
          <Wait visible={loading} />
        </Dialog>
      </>
    )
  }

  return { DeleteAffiliatePopup, setOpen, open, setError, setOpenErrorModal }
}
