import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ChevronDownIcon,
  DropdownMenu,
  LoadingIcon,
  TableContainer,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  Table,
  TableFullRow,
  ClipboardIcon,
  Pagination,
} from '@skywatch/ui'
import { useCollection } from '@skywatch/core'
import { states, stages, statuses, Quote } from './store/types'
import quotesCollection from './store/quotes.collection'
import { GetQuotesGridQueryRequest } from './store/types'
import { getStatusElement, UserMonitoringDashboardHeader } from './components'
import { CreditCardIcon, CreditCardLockIcon } from '@drive/shared'
import { useSession } from '@drive/auth'

const ITEM_LIMIT = 10

export default () => {
  const { state } = useSession()
  const quotes = useCollection(quotesCollection)
  const [filtering, setFiltering] = useState<GetQuotesGridQueryRequest>({
    limit: 10,
    page: 1,
    searchValue: '',
    status: '',
    stage: '',
    orderBy: '-RegistrationTime',
    state: '',
  })
  const navigate = useNavigate()

  const actions = useCallback((quote: Quote) => {
    return [
      [
        {
          key: 'backToQuoteDropDown',
          text: 'Back to Quote',
          icon: ClipboardIcon,
          action: () => navigate(`/user/${quote.userId}/insurance`),
          disable: quote.status === 'Bound',
        },
        {
          key: 'goToProdileDropDown',
          text: 'Go to Profile',
          icon: CreditCardIcon,
          action: () => navigate(`/user/${quote.userId}/profile`),
        },
        {
          key: 'declinationReferralsDropDown',
          text: 'Declinations & referrals',
          icon: CreditCardLockIcon,
          action: () => navigate(`/user/${quote.userId}/declinations`),
        },
      ].filter(ac => state.permissions.includes(ac.key)),
    ]
  }, [])

  const getData = async () => {
    quotes.reset()
    await quotes.fetchQuoteDataGrid(filtering)
  }

  const onSearch = (searchValue: string) => {
    setFiltering(prev => ({ ...prev, searchValue: searchValue }))
  }

  const onPageChange = (page: number) => {
    setFiltering(prev => ({
      ...prev,
      page: page,
    }))
  }

  const statusMenu = [
    [{ text: 'All', action: () => setFiltering(prev => ({ ...prev, status: '' })) }].concat(
      statuses.map(status => {
        return {
          text: status,
          action: () =>
            setFiltering(prev => ({
              ...prev,
              status: status,
            })),
        }
      })
    ),
  ]

  const stageMenu = [
    [{ text: 'All', action: () => setFiltering(prev => ({ ...prev, stage: '' })) }].concat(
      stages.map(stage => {
        return {
          text: stage,
          action: () =>
            setFiltering(prev => ({
              ...prev,
              stage: stage,
            })),
        }
      })
    ),
  ]

  const stateMenu = [
    [{ text: 'All', action: () => setFiltering(prev => ({ ...prev, state: '' })) }].concat(
      states.map(state => {
        return {
          text: state,
          action: () =>
            setFiltering(prev => ({
              ...prev,
              state: state,
            })),
        }
      })
    ),
  ]

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering])

  return (
    <>
      <UserMonitoringDashboardHeader searchValue={filtering.searchValue} onSearchChange={onSearch} />
      <TableContainer className="overflow-x-auto">
        <Table>
          <TableThead>
            <TableTr>
              <TableTh>
                <a href="#" className="group inline-flex">
                  Registration Time
                  <span
                    onClick={() =>
                      setFiltering(prev => ({
                        ...prev,
                        orderBy: prev.orderBy === 'RegistrationTime' ? '-RegistrationTime' : 'RegistrationTime',
                      }))
                    }
                    className="ml-2 flex flex-col justify-center rounded-xl bg-gray-100 text-gray-400  hover:bg-gray-200"
                  >
                    <ChevronDownIcon
                      className={`h-5 w-5 stroke-gray-950 ${
                        filtering.orderBy === 'RegistrationTime' ? 'rotate-180' : ''
                      }`}
                      aria-hidden="true"
                    />
                  </span>
                </a>
              </TableTh>
              <TableTh>
                <a href="#" className="group inline-flex">
                  Full Name
                </a>
              </TableTh>
              <TableTh>
                <a href="#" className="group inline-flex">
                  User Email
                </a>
              </TableTh>

              <TableTh>
                <a href="#" className="group inline-flex">
                  Phone Number
                </a>
              </TableTh>

              <TableTh>
                <DropdownMenu items={statusMenu}>
                  <a href="#" className="group inline-flex items-center">
                    <div className="flex flex-col">
                      Status
                      {filtering.status && <span className="text-xs text-gray-400">({filtering.status})</span>}
                    </div>
                    <span className="ml-2 flex-none rounded-xl bg-gray-100 text-gray-900 hover:bg-gray-200">
                      <ChevronDownIcon className="h-5 w-5 stroke-gray-950" aria-hidden="true" />
                    </span>
                  </a>
                </DropdownMenu>
              </TableTh>
              <TableTh>
                <DropdownMenu items={stageMenu}>
                  <a href="#" className="group inline-flex items-center">
                    <div className="flex flex-col">
                      Stage
                      {filtering.stage && <span className="text-xs text-gray-400">({filtering.stage})</span>}
                    </div>
                    <span className="ml-2 flex-none rounded-xl bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                      <ChevronDownIcon className="h-5 w-5 stroke-gray-950" aria-hidden="true" />
                    </span>
                  </a>
                </DropdownMenu>
              </TableTh>
              <TableTh>
                <DropdownMenu items={stateMenu} className="h-96 overflow-y-scroll">
                  <a href="#" className="group inline-flex items-center ">
                    <div className="flex flex-col ">
                      State
                      {filtering.state && <span className="text-xs text-gray-400">({filtering.state})</span>}
                    </div>
                    <span className="ml-2 flex-none rounded-xl bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                      <ChevronDownIcon className="h-5 w-5 stroke-gray-950" aria-hidden="true" />
                    </span>
                  </a>
                </DropdownMenu>
              </TableTh>
              <TableTh></TableTh>
            </TableTr>
          </TableThead>
          <tbody className={`${quotes.isBusy ? 'relative' : ''}`}>
            {quotes.isBusy && (
              <div className="absolute mt-3 flex h-full w-full flex-row items-center justify-center bg-white/70 ">
                <LoadingIcon className="stroke-primary-600 h-16 w-16 animate-spin flex-row justify-center" />
              </div>
            )}
            {quotes.items.map(quote => (
              <TableTr key={quote.id} className="h-12">
                <TableTd>
                  {quote.creationDate?.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </TableTd>
                <TableTd>
                  {quote.holderFirstName && quote.holderLastName
                    ? `${quote.holderFirstName} ${quote.holderMiddleName || ''} ${quote.holderLastName}`
                    : ''}
                </TableTd>
                <TableTd>{quote.email}</TableTd>
                <TableTd>{quote.phone}</TableTd>
                <TableTd>{getStatusElement(quote.status)}</TableTd>
                <TableTd>{quote.stage}</TableTd>
                <TableTd>{quote.state}</TableTd>
                <tbody>
                  <TableTd key={quote.id}>
                    <DropdownMenu
                      items={actions(quote)}
                      className="z-10 flex w-max content-center self-center stroke-black"
                    >
                      <a href="#" className="flex flex-row items-center self-center">
                        <div className="text-primary-500 flex flex-col">Actions</div>
                        <ChevronDownIcon className="stroke-primary-500 h-5 w-5" aria-hidden="true" />
                      </a>
                    </DropdownMenu>
                  </TableTd>
                </tbody>
              </TableTr>
            ))}
            {[...new Array(Math.abs(quotes.count - ITEM_LIMIT))].map((q, i) => (
              <TableFullRow className="h-12" cols={6} key={q}>
                {quotes.count === 0 && i === 0 ? (
                  <span className="flex flex-row justify-center text-lg font-medium text-gray-400">
                    No quotes found
                  </span>
                ) : undefined}
              </TableFullRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <Pagination
        defaultPage={1}
        maxPages={Math.ceil(quotes.state.meta?.total / ITEM_LIMIT)}
        visableRange={5}
        onPrev={onPageChange}
        onNext={onPageChange}
        onClick={onPageChange}
      />
    </>
  )
}
