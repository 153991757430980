import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BackendError } from '@skywatch/core'
import { ArrowLeftIcon, BuildingsIcon, Button } from '@skywatch/ui'
import { Wait, useErrorModal } from '@drive/shared'
import AffiliateNewForm from './forms/AffiliateNewForm'
import BackHeader from './components/BackHeader'
import { AffiliateNewType } from './forms/types/affiliate'
import { useAffiliate } from './hooks'
import { useSession } from '@drive/auth'

export default () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const parentKey = searchParams.get('parentKey')
  const parentName = searchParams.get('parentName')
  const affiliateResource = useAffiliate()
  const [error, setError] = useState<BackendError>()
  const { setOpen: setOpenErrorModal, ErrorModal } = useErrorModal()
  const { isAdmin } = useSession()

  const onSubmit = async (values: AffiliateNewType) => {
    try {
      setLoading(true)
      const res = await affiliateResource.createAffiliate(values)
      if (isAdmin) {
        await affiliateResource.updateAffiliatePaymentFees(res.id, values.paymentFees)
      }
      navigate(`/affiliates/dashboard/agencies`)
    } catch (e: any) {
      setError({ message: e.messages[0].messages[0].message, title: e.messages[0].messages[0].payload })
      setOpenErrorModal(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <main className="flex h-[calc(100vh-60px)] flex-col items-center overflow-auto bg-[#F2F2F2]">
      <div className="flex w-2/3 flex-col ">
        <div className="flex w-full">
          <BackHeader
            onBack={() => navigate(-1)}
            text="Add New Agency Partnership"
            icon={<BuildingsIcon className="stroke-black stroke-2" />}
          />
        </div>
        <div className="rounded-xl bg-white">
          <AffiliateNewForm
            isAdmin={isAdmin}
            parentName={parentName}
            defaultValues={{ network: parentKey || undefined }}
            onSubmitForm={onSubmit}
          />
          {/* divider */}
          <div className="col-span-6 mb-6 block border-b border-gray-300" />
          <div className=" flex flex-row justify-between px-6 pb-6">
            <Button
              onClick={() => navigate(-1)}
              buttonType={'gray'}
              variant={'outline'}
              size="large"
              className="w-full font-medium md:w-fit"
            >
              Cancel
            </Button>{' '}
            <Button
              type="submit"
              buttonType="success"
              size="large"
              form="affiliate-new-form"
              className="w-full font-medium md:w-fit"
            >
              Save Agency Details
              <ArrowLeftIcon className=" rotate-180" />
            </Button>
          </div>
        </div>
      </div>
      <Wait visible={loading} />
      <ErrorModal errorText={error?.message || undefined} errorTitle={error?.title} />
    </main>
  )
}
