import * as E from 'effect/Either'
import { pipe } from 'effect/Function'
import { Collection, createCollection, CreateThunksReturn, initCollection } from '@skywatch/core'
import { AffiliateGrid, GetAllAffiliatesByQueryRequest } from './types/affiliate'
import { getAffiliateGridDataEndpoint } from '~/api'

const fetchAllAffiliatesByQuery = async (getAllAffiliatesByQueryRequest: GetAllAffiliatesByQueryRequest) => {
  try {
    const urlSearchParams = new URLSearchParams()

    // Convert the object to URLSearchParams
    Object.entries(getAllAffiliatesByQueryRequest).forEach(([key, value]) => {
      urlSearchParams.append(key, value.toString())
    })

    const res = await getAffiliateGridDataEndpoint({ query: urlSearchParams })

    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          const affiliates = apiResponse.data.objects.map(aff => AffiliateGrid.fromApi(aff))
          return {
            objects: affiliates,
            meta: apiResponse.data.meta,
          }
        },
      })
    )
  } catch (e) {
    throw new Error()
  }
}

export default createCollection({
  name: 'affiliates',
  initialState: initCollection<Collection<AffiliateGrid>>(),
  asyncMethods: (thunks: CreateThunksReturn<AffiliateGrid>) => ({
    getAllAffiliatesByQuery: thunks.upsertMany(fetchAllAffiliatesByQuery),
  }),
})
