import { DefaultValues, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from '@skywatch/ui'
import BrokerDetails, { BrokerType } from './types/broker'

type BrokerFormProps = {
  onSubmitForm: (data: BrokerType) => void
  defaultValues?: DefaultValues<BrokerType>
}

export default ({ defaultValues, onSubmitForm }: BrokerFormProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<BrokerType>({
    resolver: zodResolver(BrokerDetails),
    defaultValues: defaultValues || {},
  })
  return (
    <form id="broker-form" onSubmit={handleSubmit(onSubmitForm)}>
      <Form.Grid className="pt-3">
        {/* First Name */}
        <Form.Control className="md:!col-span-6">
          <Form.Label>First name</Form.Label>
          <Form.Input
            className={`input ${errors.firstName && 'input-error'}`}
            {...register('firstName')}
            placeholder="First name"
          />
          <Form.Error className={`${!errors.firstName?.message && 'hidden'}`}>{errors.firstName?.message}</Form.Error>
        </Form.Control>
        {/* Last Name */}
        <Form.Control className="md:!col-span-6">
          <Form.Label>Last name</Form.Label>
          <Form.Input
            className={`input ${errors.lastName && 'input-error'}`}
            {...register('lastName')}
            placeholder="Last name"
          />
          <Form.Error className={`${!errors.lastName?.message && 'hidden'}`}>{errors.lastName?.message}</Form.Error>
        </Form.Control>

        {/* Email Name */}
        <Form.Control className="md:!col-span-6">
          <Form.Label>Email</Form.Label>
          <Form.Input className={`input ${errors.email && 'input-error'}`} {...register('email')} placeholder="Email" />
          <Form.Error className={`${!errors.email?.message && 'hidden'}`}>{errors.email?.message}</Form.Error>
        </Form.Control>

        {/* phone number  */}
        <Form.Control className="md:!col-span-6">
          <Form.Label>Phone number</Form.Label>
          <Form.Input
            className={`input ${errors.phoneNumber && 'input-error'}`}
            {...register('phoneNumber')}
            placeholder="Phone number"
          />
          <Form.Error className={`${!errors.phoneNumber?.message && 'hidden'}`}>
            {errors.phoneNumber?.message}
          </Form.Error>
        </Form.Control>
      </Form.Grid>
    </form>
  )
}
