import { z } from 'zod'

export const AffiliateGridDataSchema = z.object({
  affiliateName: z.string(),
  affiliateKey: z.string(),
  parentName: z.string().optional(),
  parentsKeys: z.string().array().optional(),
  disabled: z.boolean(),
})

export const AffiliatesGridSchema = z.object({
  objects: z.array(AffiliateGridDataSchema),
  meta: z.object({
    total: z.number(),
    offset: z.number(),
    limit: z.number(),
  }),
})

export type AffiliatesGridSchema = z.infer<typeof AffiliatesGridSchema>
export type AffiliateGridDataSchema = z.infer<typeof AffiliateGridDataSchema>
