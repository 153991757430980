import { getToken } from '@skywatch/core'
import { ReportTypeListType } from '~/pages/Reports/types'

const { VITE_VOOM_API_PATH } = import.meta.env

export const generateReportEndpoint = async (
  reportType: ReportTypeListType,
  startDate?: Date,
  endDate?: Date,
  state?: string,
  file?: File
) => {
  const formData = new FormData()
  formData.append('reportType', reportType)
  endDate && formData.append('endDate', endDate.toISOString())
  startDate && formData.append('startDate', startDate.toISOString())
  state && formData.append('state', state.toString())
  file && formData.append('file', file)

  const url = `${VITE_VOOM_API_PATH}/reports/createReport`
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

  if (
    response.headers.get('content-type') === 'application/octet-stream' ||
    response.headers.get('content-type') === 'text/csv'
  ) {
    return {
      data: await response.blob(),
      statusCode: response.status,
      ok: response.ok,
    }
  }

  const text = await response.text()
  const jsonData = text === '' ? {} : JSON.parse(text)

  return {
    data: jsonData,
    statusCode: response.status,
    ok: response.ok,
  }
}
