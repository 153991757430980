import { useState } from 'react'
import { Button, useDialog } from '@skywatch/ui'
import { BackendError } from '@skywatch/core'
import { Wait, useErrorModal } from '@drive/shared'
import { useSession } from '@drive/auth'
import { AffiliateEditType } from '../forms'
import AffiliateEditForm from '../forms/AffiliateEditForm'
import { useAffiliate } from '.'

export default () => {
  const affiliateResource = useAffiliate()
  const { setOpen, Dialog, open } = useDialog()
  const [error, setError] = useState<BackendError>()
  const { setOpen: setOpenErrorModal, ErrorModal } = useErrorModal()
  const { isAdmin } = useSession()

  const onSubmit = async (values: AffiliateEditType) => {
    try {
      const res = await affiliateResource.updateAffiliate(affiliateResource.state, values)
      if (isAdmin) {
        await affiliateResource.updateAffiliatePaymentFees(res.id, values.paymentFees)
      }
      setOpen(false)
    } catch (e: any) {
      setError({ message: e.messages[0].messages[0].message, title: e.messages[0].messages[0].payload })
      setOpenErrorModal(true)
    }
  }

  const EditAffiliatePopup = () => (
    <>
      <ErrorModal errorText={error?.message || undefined} errorTitle={error?.title} />
      <Dialog>
        <AffiliateEditForm
          isAdmin={isAdmin}
          defaultValues={{
            name: affiliateResource.state.name,
            description: affiliateResource.state.description,
            paymentFees: {
              registrationFee: affiliateResource.state.registrationFee,
              quoteFee: affiliateResource.state.qouteFee,
              bindCommission: affiliateResource.state.bindCommission,
            },
          }}
          onSubmitForm={onSubmit}
        />
        <div className="mt-8 grid grid-cols-2 gap-3">
          <Button
            onClick={() => setOpen(false)}
            buttonType={'gray'}
            variant={'outline'}
            className="col-span-2 w-full md:col-span-1"
          >
            Cancel
          </Button>{' '}
          <Button
            type="submit"
            buttonType="primary"
            form="affiliate-edit-form"
            className="col-span-2 w-full md:col-span-1"
          >
            Save Changes
          </Button>{' '}
        </div>
        <Wait visible={affiliateResource.state.status === 'busy'} />
      </Dialog>
    </>
  )
  return { EditAffiliatePopup, setOpen, open }
}
