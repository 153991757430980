import { Outlet } from 'react-router-dom'
import { Provider } from './store/context'
import { Page } from '@skywatch/ui'
import { Header } from '@drive/shared'
import { useSession } from '@drive/auth'

export default () => {
  const session = useSession()

  return (
    <Provider>
      <Page className="font-poppins flex h-screen touch-none flex-col bg-white">
        <Header admin={session.state.role === 'Admin'} />
        <Outlet />
      </Page>
    </Provider>
  )
}
