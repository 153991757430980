import { z } from 'zod'
import BrokerDetails from './broker'

const AffiliatePaymentFees = z.object({
  registrationFee: z.boolean().default(true),
  quoteFee: z.boolean().default(true),
  bindCommission: z.boolean().default(true),
})

export type AffiliatePaymentFeesType = z.infer<typeof AffiliatePaymentFees>

const AffiliateNewDetails = z.object({
  description: z.string().trim().optional(),
  name: z.string().trim().min(1, { message: 'Required' }),
  network: z.string().optional(),
  admin: BrokerDetails,
  paymentFees: AffiliatePaymentFees.optional(),
})

export type AffiliateNewType = z.infer<typeof AffiliateNewDetails>

const AffiliateEditDetails = z.object({
  description: z.string().optional(),
  name: z.string().min(1, { message: 'Required' }),
  paymentFees: AffiliatePaymentFees.optional(),
})

export type AffiliateEditType = z.infer<typeof AffiliateEditDetails>

export { AffiliateNewDetails, AffiliateEditDetails, AffiliatePaymentFees }
