import { BuildingIcon, BuildingsIcon } from '@skywatch/ui'
import { Tab } from '~/components/TabSelector'

export const affiliateTabs: Tab[] = [
  {
    key: 'networks',
    text: 'Broker Networks',
    icon: BuildingsIcon,
    url: '/affiliates/dashboard/networks',
  },
  {
    key: 'agencies',
    text: 'Agencies',
    icon: BuildingIcon,
    url: '/affiliates/dashboard/agencies',
  },
]
