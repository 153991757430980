import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useProfile } from '@drive/auth'
import { UserEmail } from '~/models'
import { getPoliciesEndpoint } from '~/api'
import { AutoComplete } from '@drive/shared'

const Schema = z.object({
  email: z
    .string({ required_error: 'Required' })
    .toLowerCase()
    .email({ message: 'Please enter a valid email address' }),
})
export type EmailSchemaType = z.infer<typeof Schema>

type Props = {
  title: string
  // url: string
}
export default ({ title }: Props) => {
  const navigate = useNavigate()
  const { getUser: getImpersonationUser } = useProfile()
  const [application, setApplication] = useState<UserEmail | null>()

  const fetchPolicies = async (query: string) => {
    return (await getPoliciesEndpoint(null, { queryParams: { username: query } })) as any
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    if (!application) return
    const user = await getImpersonationUser(application.userId)

    navigate(`user/${user.id}/policy`)
  }

  return (
    <div>
      <div className="mb-1 text-lg font-semibold">{title}</div>
      <div className="mb-5 text-gray-500">Please insert customer email</div>
      <form id="modal" onSubmit={onSubmit}>
        <AutoComplete onChange={setApplication} fetchEndpoint={fetchPolicies} />
      </form>
    </div>
  )
}
