import { createEndpointV2 } from '@skywatch/core'
import { z } from 'zod'
import { AffiliatePaymentFees } from '~/pages/Affiliates/forms'
import { ApiAffiliate, ApiPostAffiliate } from '~/pages/Affiliates/model/affiliate'

export const getAffiliateByKeyEndpoint = createEndpointV2({
  method: 'GET',
  urlTemplate: 'api/affiliations/${affiliateKey}',
  useAuthorization: true,
  validations: {
    response: ApiAffiliate,
  },
})

export const getAllAffiliatesEndpoint = createEndpointV2({
  method: 'GET',
  urlTemplate: 'api/affiliations',
  useAuthorization: true,
  validations: {
    response: z.object({
      objects: z.array(ApiAffiliate),
    }),
  },
})

export const createAffiliateEndpoint = createEndpointV2({
  method: 'POST',
  urlTemplate: 'api/affiliations',
  useAuthorization: true,
  validations: {
    request: ApiPostAffiliate,
  },
})

export const createAffiliateWithParentEndpoint = createEndpointV2({
  method: 'POST',
  urlTemplate: 'api/affiliations/${affiliateKey}',
  useAuthorization: true,
  validations: {
    request: ApiPostAffiliate,
  },
})

export const disableAffiliateEnpoint = createEndpointV2({
  method: 'DELETE',
  urlTemplate: 'api/affiliations/${affiliateKey}',
  useAuthorization: true,
})

export const updateAffiliateEndpoint = createEndpointV2({
  method: 'PUT',
  urlTemplate: 'api/affiliations/${affiliateKey}',
  useAuthorization: true,
})

export const getAllBrokersEndpoint = createEndpointV2({
  method: 'GET',
  urlTemplate: 'api/affiliations/${affiliateKey}/brokers',
  useAuthorization: true,
})

export const updateAffiliatePaymentFeesEndpoint = createEndpointV2({
  method: 'PUT',
  urlTemplate: 'api/affiliations/${affiliateKey}/paymentFees',
  useAuthorization: true,
  validations: {
    request: AffiliatePaymentFees,
    response: ApiAffiliate,
  },
})
