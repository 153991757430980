import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter, Routes, Route, useParams, useNavigate } from 'react-router-dom'
import { isApiError } from '@skywatch/core'
import { NotFoundPage, EntrancePage, Wait } from '@drive/shared'
import { ProtectedRoute, AuthRouter, useProfile } from '@drive/auth'
import { Dashboard, HomePage, Reports, UserProfilePage, OdometerManager } from '~/pages'
import { AffiliateDashboard, AffiliateNew, AffiliatePage, Affiliation } from '~/pages/Affiliates'

const AcquisitionModule = lazy(() => import('@drive/acquisition'))
const BillingModule = lazy(() => import('@drive/billing'))
const PolicyModule = lazy(() => import('@drive/policy'))
const ProvidersModule = lazy(() => import('@drive/providers'))
const MidtermModule = lazy(() => import('@drive/midterm'))
const ProfileModule = lazy(() => import('@drive/profile'))
const DeclinationModule = lazy(() => import('@drive/declination'))
const UserRoutes = () => {
  const profile = useProfile()
  const { userId } = useParams()
  const navigate = useNavigate()
  const fetchUserFromUrlParam = async (userId: number) => {
    try {
      await profile.getUser(userId)
    } catch (error) {
      if (isApiError(error) && error.code === 404) {
        navigate('/404')
      } else {
        console.log('unexpected error', error)
      }
    }
  }
  useEffect(() => {
    if (userId && profile.state.id === 0) {
      fetchUserFromUrlParam(+userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  if (profile.state.id === 0) return <Wait />
  return (
    <Routes>
      <Route path="" element={<UserProfilePage />} />
      <Route
        path="insurance/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <AcquisitionModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="policy/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <PolicyModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="billing/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <BillingModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="providers/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <ProvidersModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="profile/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <ProfileModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="declinations/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <DeclinationModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="edit-policy/:policyId/midterm/:midtermType/*"
        element={
          <Suspense fallback={<Wait />}>
            <ProtectedRoute>
              <MidtermModule />
            </ProtectedRoute>
          </Suspense>
        }
      />
    </Routes>
  )
}
const AppRouter = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="auth/*" element={<AuthRouter isAdmin={true} />} />
      <Route path="entrance" element={<EntrancePage />} />
      <Route path="reports" element={<Reports />} />
      <Route path="dashboard/:tab" element={<Dashboard />} />
      <Route path="odometer-manager/:policyId" element={<OdometerManager />} />
      <Route path="user/:userId/*" element={<UserRoutes />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="affiliates" element={<Affiliation />}>
        <Route path="new" element={<AffiliateNew />} />
        <Route path="dashboard/:tab" element={<AffiliateDashboard />} />
        <Route path="details/:affiliateId" element={<AffiliatePage />} />
      </Route>
    </Routes>
  )
}
export default () => {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  )
}
