import { z } from 'zod'

export const ApiBroker = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string().optional(),
  role: z.string(),
})

export type ApiBroker = z.infer<typeof ApiBroker>

export const ApiPostBroker = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string().optional(),
})

export type ApiPostBroker = z.infer<typeof ApiPostBroker>
