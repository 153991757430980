import { createEndpointV2 } from '@skywatch/core'
import { ApiBroker, ApiPostBroker } from '~/pages/Affiliates/model'

export const createBrokerEndpoint = createEndpointV2({
  method: 'POST',
  urlTemplate: 'api/broker/${affiliateKey}',
  useAuthorization: true,
  validations: {
    response: ApiBroker,
    request: ApiPostBroker,
  },
})

export const updateBrokerEndpoint = createEndpointV2({
  method: 'PUT',
  urlTemplate: 'api/broker/${userId}',
  useAuthorization: true,
  validations: {
    response: ApiBroker,
    request: ApiPostBroker,
  },
})

export const deleteBrokerEndpoint = createEndpointV2({
  method: 'DELETE',
  urlTemplate: 'api/broker/${userId}',
  useAuthorization: true,
})
