import {
  AlertCircle,
  ClockRefreshIcon,
  SpeedometerIcon,
  FileShieldIcon,
  CheckIcon,
  CloseIcon,
  UploadCloudNoBorderIcon,
  ChatIcon,
  Button,
  UserRightIcon,
  Chip,
} from '@skywatch/ui'
import DashboardHeader from '~/components/DashboardHeader'
import { Tab } from '~/components/TabSelector'
import useContinueQuoteDialog from '~/hooks/useContinueQuoteDialog'
import { useSession } from '@drive/auth'

export const UserMonitoringTabs: Tab[] = [
  {
    key: 'quotes',
    text: 'Recent Submissions',
    icon: ClockRefreshIcon,
    url: '/dashboard/quotes',
  },
  {
    key: 'policies',
    text: 'Policies Management',
    icon: FileShieldIcon,
    url: '/dashboard/policies',
  },
  {
    key: 'odometers',
    text: 'Odometers Monitoring',
    icon: SpeedometerIcon,
    url: '/dashboard/odometers',
  },
]

export const getStatusElement = (status: string | undefined) => {
  switch (status) {
    /// Quote status
    case 'Bound':
      return (
        <Chip className="text-success-500 border-success-200" type="success">
          <div>{status}</div>
        </Chip>
      )
    case 'Quoted':
      return (
        <Chip className="text-secondary-500 border-secondary-200" type="secondary">
          <div>{status}</div>
        </Chip>
      )
    case 'Application':
      return (
        <Chip className="border-gray-200 text-gray-500" type="gray">
          <div>{status}</div>
        </Chip>
      )
    /// Polcy status
    case 'Active':
      return (
        <Chip className="text-success-500 border-success-200" type="success">
          <CheckIcon className="stroke-success-500 h-3 w-3 stroke-2" />
          <div>{status}</div>
        </Chip>
      )
    case 'Expired':
      return (
        <Chip className="text-error-500 border-error-200" type="error">
          <AlertCircle className="stroke-error-500 h-3 w-3 stroke-2" />
          <div>{status}</div>
        </Chip>
      )
    case 'Canceled':
      return (
        <Chip className="border-gray-200 text-gray-500" type="gray">
          <CloseIcon className="h-3 w-3 stroke-gray-500 stroke-2" />
          <div>{status}</div>
        </Chip>
      )
    case 'Scheduled':
      return (
        <Chip className="text-lightBlue-500 border-lightBlue-200" type="lightBlue">
          <ClockRefreshIcon className="stroke-lightBlue-500 h-3 w-3 stroke-2" />
          <div>{status}</div>
        </Chip>
      )
    /// Odometer status
    case 'Approved':
      return (
        <Chip className="text-success-500 border-success-200" type="success">
          <CheckIcon className="stroke-success-500 h-3 w-3 stroke-2" />
          <div>{status}</div>
        </Chip>
      )
    case 'Pending Upload':
      return (
        <Chip className="text-primary-500 border-primary-200" type="primary">
          <UploadCloudNoBorderIcon className="stroke-primary-500 h-3 w-3" />
          <div>{status}</div>
        </Chip>
      )
    case 'Pending Initial':
      return (
        <Chip className="text-lightBlue-500 border-lightBlue-200" type="lightBlue">
          <AlertCircle className="stroke-lightBlue-500 h-3 w-3 stroke-2" />
          <div>{status}</div>
        </Chip>
      )
    case 'Pending Approval':
      return (
        <Chip className="text-error-500 border-error-200" type="error">
          <ChatIcon className="stroke-error-500 h-3 w-3 stroke-1" />
          <div>{status}</div>
        </Chip>
      )
    default:
      return <div className="w-fit rounded-full border px-2">{status}</div>
  }
}

type HeaderProps = {
  onSearchChange: (value: string) => void
  searchValue: string
}

export const UserMonitoringDashboardHeader = ({ onSearchChange, searchValue }: HeaderProps) => {
  const { state: user } = useSession()
  const { setOpen: setOpenContinueQuote, ContinueQuoteDialog } = useContinueQuoteDialog()

  return (
    <>
      <DashboardHeader
        tabs={UserMonitoringTabs.filter(tab => user?.permissions.includes(tab.key))}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        inputPlaceHolder="Email / Phone number / Name"
        sideButton={
          <Button
            className="flex flex-row px-4 py-2"
            buttonType={'gray'}
            variant={'outline'}
            size={'small'}
            onClick={() => setOpenContinueQuote(true)}
          >
            <div>
              <UserRightIcon className="stroke-gray-700" />{' '}
            </div>
            <div className="font-semibold text-gray-700">Continue a Quote</div>
          </Button>
        }
      />
      <ContinueQuoteDialog />
    </>
  )
}
