import { ArrowLeftIcon } from '@skywatch/ui'
import { ReactNode } from 'react'

type Props = {
  onBack: () => void
  icon: ReactNode
  text: string
}

export default ({ onBack, text, icon }: Props) => {
  return (
    <div className="my-10 flex flex-row">
      <button onClick={onBack}>
        <ArrowLeftIcon className="stroke-black stroke-2" />
      </button>
      <div>
        <div className=" ml-4 flex flex-row gap-3">
          <div className="rounded-lg border bg-white p-2">{icon}</div>
          <div className=" font-poppins flex items-center text-xl font-semibold">{text}</div>
        </div>
      </div>
    </div>
  )
}
