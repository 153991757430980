import * as E from 'effect/Either'
import { pipe } from 'effect/Function'
import { useCollection, useStore } from '@skywatch/core'
import affiliateCollection from '../store/affiliate.collection'
import affiliateResource from '../store/affiliate.resource'
import { GetAllAffiliatesByQueryRequest } from '../store/types/affiliate'
import { disableAffiliateEnpoint, updateAffiliatePaymentFeesEndpoint } from '~/api/affiliate'
import { AffiliatePaymentFeesType } from '../forms'

export const useAffiliates = () => {
  const baseHook = useCollection(affiliateCollection)

  const deleteAffiliateAndFetchAll = async (
    affiliateId: string,
    getAllAffiliatesByQueryRequest: GetAllAffiliatesByQueryRequest
  ) => {
    const res = await disableAffiliateEnpoint({ urlParams: { affiliateKey: affiliateId } })
    pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: async _ => {
          baseHook.reset()
          await baseHook.getAllAffiliatesByQuery(getAllAffiliatesByQueryRequest)
        },
      })
    )
  }

  return { ...baseHook, deleteAffiliateAndFetchAll }
}

export const useAffiliate = () => {
  const baseHook = useStore(affiliateResource)

  return { ...baseHook }
}

export { default as useEditAffiliateDialog } from './useEditAffiliateModal'
export { default as useDeleteAffiliateDialog } from './useDeleteAffiliateModal'
export { default as useBrokerDialog } from './useBrokerModal'
