import { EditIcon, TrashIcon } from '@skywatch/ui'
import { Broker } from '../store/types/broker'

type Props = {
  onDelete: (broker: Broker) => void
  onEdit: (broker: Broker) => void
  brokers: Broker[]
}

export default ({ onDelete, onEdit, brokers }: Props) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="font-semibold">Agents</div>
      <div className="flex flex-col gap-3">
        {brokers &&
          brokers.map((broker, index) => (
            <div key={broker.id}>
              {/* divider */}
              {index !== 0 && <div className=" mb-3 hidden border-b border-gray-300 md:col-span-6 md:block" />}
              <div className="flex flex-row justify-between text-sm">
                <div className="flex flex-row gap-8">
                  <div>
                    <div className="font-semibold text-gray-600">
                      {broker.firstName} {broker.lastName}
                    </div>
                    <div className="text-gray-600">{broker.email}</div>
                  </div>
                  {broker.role === 'Broker_Admin' && (
                    <div className="flex h-fit self-center rounded-full border border-gray-200 bg-gray-50 px-2 text-xs text-gray-700">
                      Admin Agent
                    </div>
                  )}
                </div>
                <div className="flex flex-row items-center gap-3">
                  <EditIcon
                    onClick={() => onEdit(broker)}
                    className="mr-2 stroke-gray-400 stroke-2 hover:cursor-pointer"
                  />
                  {broker.role !== 'Broker_Admin' && (
                    <TrashIcon
                      onClick={() => onDelete(broker)}
                      className="mr-2 stroke-gray-400 stroke-2 hover:cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
