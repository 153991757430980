import { useNavigate } from 'react-router-dom'
import { DotsIcon, DropdownMenu, EditIcon, PlusIcon } from '@skywatch/ui'
import { useAffiliate, useDeleteAffiliateDialog, useEditAffiliateDialog } from '../hooks'

export default () => {
  const navigate = useNavigate()
  const affiliateResource = useAffiliate()
  const { EditAffiliatePopup, setOpen: setEditAffiliateOpen } = useEditAffiliateDialog()

  const { DeleteAffiliatePopup } = useDeleteAffiliateDialog({ affiliate: affiliateResource.state })

  const actions = [
    [
      { text: 'Edit Egency Details', icon: EditIcon, action: () => setEditAffiliateOpen(true) },
      {
        text: 'Add Sub-Agency',
        icon: PlusIcon,
        action: () =>
          navigate(
            `/affiliates/new?parentKey=${affiliateResource.state.id}&parentName=${affiliateResource.state.name}`
          ),
      },
    ],
  ]

  return (
    <div className="flex flex-row justify-between">
      <div>
        <div className="text-2xl font-semibold">{affiliateResource.state.name}</div>
        <div className="my-1 text-gray-600">{affiliateResource.state.description}</div>
      </div>
      <div className="flex items-center">
        <DropdownMenu items={actions} className="z-10 flex w-max stroke-black">
          <DotsIcon className="mr-2 stroke-gray-400 stroke-2" />
        </DropdownMenu>
      </div>
      <EditAffiliatePopup />
      <DeleteAffiliatePopup />
    </div>
  )
}
