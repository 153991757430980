import { USStateKeys } from '@drive/shared'
import { z } from 'zod'

export const NewUserSchema = z.object({
  email: z
    .string({ required_error: 'Required' })
    .toLowerCase()
    .email({ message: 'Please enter a valid email address' }),
  state: z.enum(USStateKeys, {
    errorMap: () => ({ message: 'Required' }),
  }),
})

export type NewUser = z.infer<typeof NewUserSchema>
