import { useNavigate } from 'react-router-dom'
import { Header } from '@drive/shared'
import { useSession } from '@drive/auth'

export default () => {
  const navigate = useNavigate()
  const session = useSession()
  return (
    <Header
      admin={session.state.role === 'Admin'}
      userName={`${session.state?.firstName || ''} ${session.state?.middleName ? `${session.state?.middleName} ` : ''}${
        session.state?.lastName || ''
      }`}
      email={session.state?.email}
      logout={() => navigate('/auth/logout')}
    />
  )
}
