import { z } from 'zod'

export const QuoteGridDataSchema = z.object({
  quoteId: z.string(),
  userId: z.number(),
  email: z.string().optional(),
  phone: z.string().optional(),
  holderFirstName: z.string().optional(),
  holderMiddleName: z.string().optional(),
  holderLastName: z.string().optional(),
  startDate: z.coerce.date(),
  creationDate: z.coerce.date(),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  status: z.string().optional(),
  stage: z.string().optional(),
})

export const QuoteGridDataMetaSchema = z.object({
  total: z.number(),
  offset: z.number(),
  limit: z.number(),
})

export const QuoteGridSchema = z.object({
  objects: z.array(QuoteGridDataSchema),
  meta: QuoteGridDataMetaSchema,
})

export type QuoteGridDataApi = z.infer<typeof QuoteGridDataSchema>
export type QuotesGridApi = z.infer<typeof QuoteGridSchema>
