import { SVGAttributes } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export type Tab = {
  text: string
  key: string
  icon: (props: SVGAttributes<SVGSVGElement>) => JSX.Element
  url: string
}

type Props = {
  tabs: Tab[]
}

export default ({ tabs }: Props) => {
  const { tab: urlTab } = useParams()
  const navigate = useNavigate()
  return (
    <div className=" flex rounded-xl border-2 text-center text-sm text-gray-700">
      {tabs.map((tab, index) => (
        <button
          className={`flex items-center gap-2 px-5 py-2
              ${index === 0 ? 'rounded-l-xl border-r-0' : ''}
              ${index === tabs.length - 1 ? 'rounded-r-xl border-l-0' : ''}
              ${tab.key === urlTab && 'bg-primary-50 text-primary-500'}`}
          key={tab.key}
          onClick={() => navigate(tab.url)}
        >
          <tab.icon className={`${urlTab === tab.key ? 'stroke-primary-500' : 'stroke-black'} stroke-2`} />
          <p className="content-center">{tab.text}</p>
        </button>
      ))}
    </div>
  )
}
