import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { BuildingsIcon, Button, FileSearchIcon, FillterIcon, Pagination, PlusIcon } from '@skywatch/ui'
import { VoomTable } from '@drive/shared'
import { useSession } from '@drive/auth'
import BackHeader from './components/BackHeader'
import { GetAllAffiliatesByQueryRequest } from './store/types/affiliate'
import { useAffiliates, useDeleteAffiliateDialog } from './hooks'
import DashboardHeader from '~/components/DashboardHeader'
import { affiliateTabs } from './components'

type AffiliateTable = {
  name: string
  network?: string
  status: string
  actions: JSX.Element
}

export default () => {
  const affiliates = useAffiliates()
  const navigate = useNavigate()
  const { state: user } = useSession()
  const { tab } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchValue = searchParams.get('searchValue')
  const page = searchParams.get('page')
  const sortBy = searchParams.get('sortBy')
  const [filtering, setFiltering] = useState<GetAllAffiliatesByQueryRequest>({
    limit: 10,
    page: Number(page ?? 1),
    searchValue: searchValue ?? '',
    sortBy: sortBy ?? 'Status',
    onlyAgencies: tab === 'agencies',
  })

  const { DeleteAffiliatePopup } = useDeleteAffiliateDialog({
    affiliateGrid: affiliates.getSelected() ?? undefined,
  })

  const columns = useMemo<ColumnDef<AffiliateTable, unknown>[]>(
    () =>
      [
        {
          header: 'Agency',
          accessorKey: 'name',
        },
        {
          header: () => (
            <div className="flex flex-row gap-1">
              <div>Network</div>
              <FillterIcon
                className={`h-4 w-4 stroke-gray-400 stroke-2 hover:cursor-pointer ${
                  filtering.sortBy === '-Network' && 'rotate-180'
                }`}
                onClick={() => {
                  searchParams.set('sortBy', filtering.sortBy === '-Network' ? 'Network' : '-Network')
                  setSearchParams(searchParams)
                }}
              />
            </div>
          ),
          accessorKey: 'network',
        },
        {
          header: () => '',
          accessorKey: 'actions',
          cell: (info: CellContext<AffiliateTable, unknown>) => info.getValue() as JSX.Element,
        },
      ].filter(h => h.accessorKey !== 'network' || filtering.onlyAgencies),
    [filtering.sortBy, filtering.onlyAgencies, searchParams, setSearchParams]
  )
  const data = useMemo(
    () =>
      affiliates.map(aff => ({
        name: aff.affiliateName,
        network: aff.parentName ?? '',
        status: aff.disabled ? 'Deactivated' : 'Activated',
        actions: (
          <div className="mr-3 flex flex-row justify-end gap-6">
            <div className="flex flex-row gap-4">
              <button
                onClick={() => {
                  navigate(`/affiliates/details/${aff.id}`)
                }}
                className={'text-primary-600 underline underline-offset-1'}
              >
                View Agency
              </button>
            </div>
          </div>
        ),
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [affiliates, affiliates.state.selected]
  )
  useEffect(() => {
    const fetchData = async () => {
      affiliates.reset()
      await affiliates.getAllAffiliatesByQuery(filtering)
    }
    affiliates.isBusy === false && fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering])

  useEffect(() => {
    setFiltering(prev => ({
      ...prev,
      onlyAgencies: tab === 'agencies',
      sortBy: sortBy ?? 'Status',
      page: Number(page ?? 1),
      searchValue: searchValue ?? '',
    }))
  }, [tab, searchValue, sortBy, page])

  const onSearchChange = (newVal: string) => {
    setSearchParams({ searchValue: newVal })
  }

  const onPageChange = (page: number) => {
    setSearchParams({ page: page.toString() })
  }

  return (
    <main className="container flex h-[calc(100vh-60px)] flex-col">
      <BackHeader
        onBack={() => navigate('/')}
        text="Agencies Management"
        icon={<BuildingsIcon className="stroke-black stroke-2" />}
      />
      <DashboardHeader
        tabs={affiliateTabs}
        searchValue={searchValue ?? ''}
        onSearchChange={onSearchChange}
        inputPlaceHolder="Search by agency name or network"
        sideButton={
          user.permissions.includes('addAgency') && (
            <Button
              className="flex h-full flex-row"
              buttonType={'gray'}
              variant={'outline'}
              size={'small'}
              onClick={() => navigate('/affiliates/new')}
            >
              <div>
                <PlusIcon stroke="black" />{' '}
              </div>
              <div className="font-semibold">Add Agency</div>
            </Button>
          )
        }
      />
      <div className="flex flex-col">
        <VoomTable<AffiliateTable>
          data={data as AffiliateTable[]}
          columns={columns}
          noDataView={
            <div className="flex flex-col items-center gap-2">
              <FileSearchIcon className="stroke-gray-500 stroke-2" />
              <div className="text-gray-500">There are no related networks</div>
            </div>
          }
          loading={affiliates.isBusy}
        />
        <Pagination
          defaultPage={1}
          maxPages={Math.ceil(affiliates.state.meta?.total / filtering.limit)}
          visableRange={5}
          onPrev={onPageChange}
          onNext={onPageChange}
          onClick={onPageChange}
        />
      </div>
      <DeleteAffiliatePopup />
    </main>
  )
}
