import { ReactNode, useEffect, useState } from 'react'
import { useDebounce } from '@skywatch/core'
import { SearchIcon } from '@skywatch/ui'
import TabSelector, { Tab } from './TabSelector'

type DashboardHeaderProps = {
  onSearchChange: (value: string) => void
  searchValue: string
  tabs: Tab[]
  inputPlaceHolder: string
  sideButton: ReactNode
}

export default ({ onSearchChange, searchValue, tabs, inputPlaceHolder, sideButton }: DashboardHeaderProps) => {
  const [inputValue, setInputValue] = useState(searchValue)
  const debouncedValue = useDebounce(inputValue, 500)

  useEffect(() => {
    if (debouncedValue.length > 2 || debouncedValue.length === 0) {
      onSearchChange(debouncedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(() => {
    setInputValue(searchValue)
  }, [searchValue])

  return (
    <div className="mb-8 mt-12 flex flex-col gap-8">
      <div className="flex flex-row justify-between text-sm">
        <TabSelector tabs={tabs} />
        <div className="content-center">{sideButton}</div>
      </div>
      <div className="flex flex-row space-x-3 text-sm ">
        <div className=" relative flex items-center">
          <div className="pointer-events-auto absolute pl-2">
            <SearchIcon stroke="#667085" />
          </div>
          <input
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            type="text"
            className="input w-96 rounded-xl pl-9"
            placeholder={inputPlaceHolder}
          />
        </div>
      </div>
    </div>
  )
}
