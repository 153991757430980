import { useEffect, useState } from 'react'
import { DefaultValues } from 'react-hook-form'
import { BackendError, useCollection } from '@skywatch/core'
import { Button, CloseIcon, useDialog } from '@skywatch/ui'
import { Wait, useErrorModal } from '@drive/shared'
import brokerCollection from '../store/broker.collection'
import { BrokerType } from '../forms/types/broker'
import BrokerForm from '../forms/BrokerForm'
import { Broker } from '../store/types/broker'
import { useAffiliate } from '.'

const createDefaultValues = (broker: Broker): DefaultValues<BrokerType> => ({
  firstName: broker.firstName,
  lastName: broker.lastName,
  phoneNumber: broker.phone,
  email: broker.email,
})

export default () => {
  const { state: affiliate } = useAffiliate()
  const brokers = useCollection(brokerCollection)
  const selectedBroker = brokers.getSelected()
  const [loading, setLoading] = useState(false)
  const { setOpen, Dialog, open } = useDialog()
  const [error, setError] = useState<BackendError>()
  const { setOpen: setOpenErrorModal, ErrorModal } = useErrorModal()

  const onSubmit = async (values: BrokerType) => {
    try {
      setLoading(true)
      if (selectedBroker?.id) {
        await brokers.updateBroker(selectedBroker.id.toString(), values)
      } else {
        await brokers.addBroker(affiliate.id, values)
      }
    } catch (e: any) {
      setError({ message: e.messages[0].messages[0].message, title: e.messages[0].messages[0].payload })
      setOpenErrorModal(true)
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!open) {
      brokers.select(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const BrokerPopup = () => (
    <>
      <ErrorModal errorText={error?.message || undefined} errorTitle={error?.title} />

      <Dialog>
        <div className="font-poppins flex flex-row justify-between text-xl font-semibold">
          <div>{selectedBroker ? 'Edit Agent Details' : 'Add a New Agent'}</div>
          <button onClick={() => setOpen(false)}>
            <CloseIcon className="stroke-gray-400 stroke-2" />
          </button>
        </div>
        <BrokerForm onSubmitForm={onSubmit} defaultValues={selectedBroker ? createDefaultValues(selectedBroker) : {}} />
        <div className=" my-6 hidden border-b border-gray-300 md:col-span-6 md:block" />
        <div className="font-poppins">
          <div className="mb-2 font-semibold">Agency Details</div>
          <div className="text-xs font-semibold">{affiliate.name}</div>
          <div className="text-gray-600">{affiliate.description}</div>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-3">
          <Button
            onClick={() => setOpen(false)}
            buttonType={'gray'}
            variant={'outline'}
            className="col-span-2 w-full md:col-span-1"
          >
            Cancel
          </Button>{' '}
          <Button type="submit" buttonType="primary" form="broker-form" className="col-span-2 w-full md:col-span-1">
            Save Agent&apos;s Details
          </Button>{' '}
        </div>
        <Wait visible={loading} />
      </Dialog>
    </>
  )
  return { BrokerPopup, setOpen, open }
}
