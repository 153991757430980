import { SVGAttributes, useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Page,
  FilePlusIcon,
  SpeedometerIcon,
  FileIcon,
  UserPlusIcon,
  ClockRefreshIcon,
  FileShieldIcon,
  UsersEditIcon,
  GraphIcon,
  FileEditIcon,
  BuildingsIcon,
  UserRightIcon,
  IconWrapper,
  useDialog,
  EmailIcon,
  Button,
  CloseIcon,
} from '@skywatch/ui'
import { useProfile, useSession } from '@drive/auth'
import { Header } from '~/components'
import useCreateUserDialog from '~/hooks/useCreateUserDialog'
import { SelectUserApplicationsDialog, SelectUserPoliciesDialog } from '~/components/Dialogs'

type ServiceItem = {
  title: string
  actions: Action[]
  icon: (props: SVGAttributes<SVGSVGElement>) => JSX.Element
}

type Action = {
  type: ServiceType
  title: string
  key: ActionKey
  icon: (props: SVGAttributes<SVGSVGElement>) => JSX.Element
  action: () => void
}

type ServiceType = 'quote' | 'newQuote' | 'manage' | 'policies' | 'visitors' | 'reports' | 'account monitoring'
export type ActionKey =
  | 'generateReport'
  | 'quotes'
  | 'policies'
  | 'odometers'
  | 'managePolicy'
  | 'continueQuote'
  | 'initiateQuote'
  | 'manageProfile'
  | 'agencies'

export default () => {
  const navigator = useNavigate()
  const { setOpen: setOpenCreateUser, CreateUserDialog } = useCreateUserDialog()
  const session = useSession()
  const user = session.state
  const { reset } = useProfile()
  const [service, setService] = useState<ServiceType | null>()
  const [Loader, setLoader] = useState(false)
  const { setOpen, Dialog } = useDialog()

  const items: ServiceItem[] = useMemo(() => {
    return [
      {
        title: 'Admin Actions',
        actions: [
          {
            key: 'generateReport',
            title: 'Generate a Report',
            icon: FilePlusIcon,
            type: 'reports',
            action: () => navigator('/reports'),
          },
          {
            key: 'agencies',
            title: 'Agencies Management',
            icon: BuildingsIcon,
            type: 'reports',
            action: () => navigator('/affiliates/dashboard/agencies'),
          },
        ],
        icon: FileIcon,
      },
      {
        title: 'Account Monitoring',
        actions: [
          {
            key: 'quotes',
            title: 'Recent Submissions',
            icon: ClockRefreshIcon,
            type: 'visitors',
            action: () => navigator('/dashboard/quotes'),
          },
          {
            key: 'policies',
            title: 'Policies Management',
            icon: FileShieldIcon,
            type: 'policies',
            action: () => navigator('/dashboard/policies'),
          },
          {
            key: 'odometers',
            title: 'Odometers Monitoring',
            icon: SpeedometerIcon,
            type: 'policies',
            action: () => navigator('/dashboard/odometers'),
          },
        ],
        icon: GraphIcon,
      },
      {
        title: 'Customer Management',
        actions: [
          {
            key: 'initiateQuote',
            title: 'Initiate a New Quote',
            icon: UserPlusIcon,
            type: 'newQuote',
            action: () => setOpenCreateUser(true),
          },
          {
            key: 'continueQuote',
            title: 'Continue a Quote',
            icon: UserRightIcon,
            type: 'quote',
            action: () => {
              setService('quote')
              setOpen(true)
            },
          },
          {
            key: 'managePolicy',
            title: 'Manage a Policy',
            icon: FileEditIcon,
            type: 'manage',
            action: () => {
              setService('manage')
              setOpen(true)
            },
          },
        ],
        icon: UsersEditIcon,
      },
    ]
  }, [navigator, setOpen, setOpenCreateUser])

  const userServices = useMemo(
    () =>
      items
        .map(service => {
          const permittedAction = service.actions.filter(action => user?.permissions.includes(action.key))
          return {
            ...service,
            actions: permittedAction,
          }
        })
        .filter(service => service.actions.length > 0),
    [items, user?.permissions]
  )

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderDialogContent = (service?: ServiceType | null) => {
    switch (service) {
      case 'manage':
        return <SelectUserPoliciesDialog title="Manage Insurance Policies" />
      case 'quote':
        return <SelectUserApplicationsDialog title="Quote a Customer" onLoading={setLoader} loading={Loader} />
      case 'policies':
        return <div></div>
      default:
        return <div />
    }
  }

  return (
    <div className="font-poppins">
      <Page className="bg-white">
        <Header />
        <div className="mx-auto mt-24 max-w-6xl text-center">
          {session.isAdmin && <div className="mb-6 text-xl">Admin Portal</div>}
          {session.isBroker && <div className="mb-6 text-xl">Broker Portal</div>}
          <div>
            Hi <span className="capitalize">{session.state?.firstName},</span>
          </div>
          <div>What would you like to do?</div>
          <div className="ml-auto mr-auto mt-20 flex flex-row flex-wrap content-center justify-center gap-x-8 gap-y-6">
            {userServices.map(item => (
              <div
                key={item.title}
                className="item-center  flex w-72 flex-col items-stretch gap-5 rounded-xl border p-4"
              >
                <div className="self-center">
                  <IconWrapper type="primary">
                    <item.icon className="stroke-primary-500 stroke-2" />
                  </IconWrapper>
                </div>
                <div className="font-semibold">{item.title}</div>
                <div className="flex flex-col items-center gap-5 text-xs text-gray-600">
                  {item.actions.map(action => (
                    <div
                      className="btn w-full justify-start rounded-xl bg-gray-50 p-4 text-gray-600 hover:bg-gray-200"
                      key={action.key}
                      onClick={() => {
                        action.action()
                      }}
                    >
                      <action.icon className="stroke-black stroke-2" />
                      {action.title}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <CreateUserDialog />
        <Dialog>
          <div className="flex justify-between">
            <div className="relative mb-2">
              <IconWrapper type="primary">
                <EmailIcon className="stroke-primary-500 stroke-2" />
              </IconWrapper>
            </div>
            <div>
              <button onClick={() => setOpen(false)}>
                <CloseIcon className="stroke-gray-500 stroke-2" />
              </button>
            </div>
          </div>
          {renderDialogContent(service)}
          <div className="mt-3 grid grid-cols-6 gap-3">
            <Button buttonType={'gray'} variant={'outline'} className="col-span-3" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button buttonType={'primary'} variant={'standard'} className="col-span-3" type="submit" form="modal">
              Continue
            </Button>
          </div>
        </Dialog>
      </Page>
    </div>
  )
}
