import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AutoComplete } from '@drive/shared'
import { UserEmail } from '~/models'
import { getEmailsEndpoint } from '~/api'

type Props = {
  title: string
  onLoading: (value: boolean) => void
  loading: boolean
}
export default ({ title }: Props) => {
  const navigate = useNavigate()
  const [application, setApplication] = useState<UserEmail | null>()

  const fetchEmails = async (query: string) => {
    return (await getEmailsEndpoint(null, { queryParams: { username: query } })) as any
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    if (!application) return
    if (application.status === 'Bound') navigate(`/user/${application.userId}/policy`)
    else navigate(`/user/${application.userId}/insurance`)
  }
  return (
    <div>
      <div className="mb-1 text-lg font-semibold">{title}</div>
      <div className="mb-5 text-gray-500">Please insert customer email</div>
      <form id="modal" onSubmit={onSubmit}>
        <AutoComplete onChange={setApplication} fetchEndpoint={fetchEmails} />
      </form>
    </div>
  )
}
