import { Button, FileUploadZone, Form } from '@skywatch/ui'
import { ReportFormObj, ReportFormType, ReportTypeList } from './types'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { States } from '@drive/shared'
import { useEffect } from 'react'

export type ReportFormProps = {
  onSubmit: (data: ReportFormType) => Promise<void>
}

export default ({ onSubmit }: ReportFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm<ReportFormType>({ resolver: zodResolver(ReportFormObj) })

  const reportTypeWatch = watch('reportType')
  useEffect(() => {
    if (reportTypeWatch != 'CGI') {
      unregister('state')
    }

    if (reportTypeWatch == 'Claims') {
      unregister('startDate')
      unregister('endDate')
    }

    if (reportTypeWatch != 'Claims') {
      unregister('file')
    }
  }, [reportTypeWatch, unregister])

  return (
    <>
      <div className="p-6 font-semibold">Generate report</div>
      {/* divider  */}
      <div className="mb-4 hidden w-full border-b border-gray-300 md:col-span-6 md:block" />
      <form id="report-form" onSubmit={handleSubmit(onSubmit)} className="w-6/12 space-y-4 pl-6">
        {/* Report type  */}
        <Form.Control>
          <Form.Label>Report type</Form.Label>
          <Form.Select className={errors?.reportType && 'input-error'} {...register('reportType', {})}>
            {ReportTypeList.map(type => {
              return (
                <option value={type} key={type}>
                  {type}
                </option>
              )
            })}
          </Form.Select>
          {errors.reportType && <Form.Error>{errors.reportType.message}</Form.Error>}
        </Form.Control>
        {reportTypeWatch == 'Claims' ? (
          <Form.Control className="grow">
            <FileUploadZone {...register('file')} fileType="text/csv" uploadText="CSV format" />
            {errors.file && <Form.Error>{errors.file.message}</Form.Error>}
          </Form.Control>
        ) : (
          <>
            {/* State  */}
            {reportTypeWatch == 'CGI' && (
              <Form.Control>
                <Form.Label>State</Form.Label>
                <Form.Select className={errors?.state && 'input-error'} {...register('state', {})}>
                  {Object.entries(States).map(([v, k]) => (
                    <option key={v} value={v}>
                      {k}
                    </option>
                  ))}
                </Form.Select>
                {errors.state && <Form.Error>{errors.state.message}</Form.Error>}
              </Form.Control>
            )}
            <div className="flex flex-row space-x-2">
              {/* Starting date  */}
              <Form.Control className="grow">
                <Form.Label>Starting date</Form.Label>
                <input
                  className={`input ${errors?.startDate ? 'input-error' : ''}`}
                  {...register('startDate')}
                  placeholder="Select date"
                  type="date"
                />
                {errors.startDate && <Form.Error>{errors.startDate.message}</Form.Error>}
              </Form.Control>
              {/* End date  */}
              <Form.Control className="grow">
                <Form.Label>End date</Form.Label>
                <input
                  className={`input ${errors?.endDate ? 'input-error' : ''}`}
                  {...register('endDate')}
                  placeholder="Select date"
                  type="date"
                />
                {errors.endDate && <Form.Error>{errors.endDate.message}</Form.Error>}
              </Form.Control>
            </div>
          </>
        )}

        <Button buttonType={'primary'} size={'small'} className="w-6/12 py-2" type="submit">
          {' '}
          Generate report
        </Button>
      </form>
    </>
  )
}
