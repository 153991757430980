import { z } from 'zod'
import BrokerDetails from '../forms/types/broker'

export const ApiAffiliate = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  logoUrl: z.string().optional(),
  parentsKeys: z.string().array(),
  url: z.string().optional(),
  disabled: z.boolean(),
  registrationFee: z.boolean(),
  qouteFee: z.boolean(),
  bindCommission: z.boolean(),
})

export type ApiAffiliate = z.infer<typeof ApiAffiliate>

export const ApiPostAffiliate = z.object({
  affiliateName: z.string(),
  description: z.string().optional(),
  logoUrl: z.string().optional(),
  parentKey: z.string().optional(),
  admin: BrokerDetails,
})

export type ApiPostAffiliate = z.infer<typeof ApiPostAffiliate>
