import { z } from 'zod'
import { OptionalPhoneNumberSchema } from '@drive/shared'

const BrokerDetails = z.object({
  firstName: z.string().trim().min(1, { message: 'Required' }),
  lastName: z.string().trim().min(1, { message: 'Required' }),
  email: z.string().trim().min(1, { message: 'Required' }).email({
    message: 'Must be a valid email',
  }),
  phoneNumber: OptionalPhoneNumberSchema,
})

export type BrokerType = z.infer<typeof BrokerDetails>

export default BrokerDetails
