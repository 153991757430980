import {
  Resource,
  createStore,
  createResourceMutations,
  composeMiddleware,
  localStorageMiddleware,
  consoleMiddleware,
  loadStateFromLocalStorage,
  createResourceThunk,
} from '@skywatch/core'
import { ApprovedOdometer, OdometerToUpload, PendingApprovalOdometer } from './types'
import { Key } from 'react'
import { ApiPolicy, ApiTransactions, getPolicyByIdEndpoint, getUserPolicyTransactionsEndpoint } from '@drive/shared'

type OdometerManager = {
  email: string
  userId: number
  state: string
  phoneNumber: string
  approvedOdometer: ApprovedOdometer[]
  pendingApprovalOdometer: PendingApprovalOdometer[]
  odometerToUpload: OdometerToUpload[]
}
type OdometerManagerResource = Resource<OdometerManager>

const defaultState: OdometerManagerResource = {
  status: 'idle',
  error: null,
  email: '',
  userId: 0,
  state: '',
  phoneNumber: '',
  approvedOdometer: [],
  pendingApprovalOdometer: [],
  odometerToUpload: [],
}
const initialState = loadStateFromLocalStorage('odometerManager', defaultState)

const mapResourceOdometerManagerToStoreObject = (
  policy: ApiPolicy,
  policyPriceBreakdown: ApiTransactions
): Partial<OdometerManager> => {
  const approvedOdometer = [] as ApprovedOdometer[]
  const pendingApprovalOdometer = [] as PendingApprovalOdometer[]
  const transactionWithOdometer = policyPriceBreakdown.filter(
    p => p.type === 'Initial' || p.type === 'OdometerUpload' || p.type === 'Midterm'
  )

  let lastTransactionVehicle
  transactionWithOdometer.forEach(transaction =>
    transaction.vehicles.forEach(vehicle => {
      const lastUploadTransaction = policyPriceBreakdown.find(
        sortedItem =>
          new Date(sortedItem.effectiveDateUtc) < new Date(transaction.effectiveDateUtc) &&
          sortedItem.status === 'Success' &&
          (sortedItem.type === 'Initial' || sortedItem.type === 'OdometerUpload')
      )
      const uploadDate = vehicle.odometer
        ? new Date(vehicle.odometer.uploadDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          })
        : ''
      switch (vehicle.status) {
        case 'Ready':
          lastTransactionVehicle = lastUploadTransaction?.vehicles.find(v => v.id == vehicle.id)
          pendingApprovalOdometer.push({
            transactionId: transaction.transactionId,
            transactionItemId: transaction.id,
            vehicleId: vehicle.id,
            vechile: `${vehicle.year}, ${vehicle.make}, ${vehicle.model}`,
            indicatedMileage: vehicle.odometer?.miles || 0,
            imageUrl: vehicle.odometer?.odometerUrl || '',
            lastVlidationValue: lastTransactionVehicle?.validateMiles ?? 0,
            uplodedDate: uploadDate,
            tncMiles: vehicle?.tncMiles || 0,
            transactionType: transaction.type,
          })
          break
        case 'Finished':
          approvedOdometer.push({
            vehicleId: vehicle.id,
            vechile: `${vehicle.year}, ${vehicle.make}, ${vehicle.model}`,
            approvedMiles: vehicle.validateMiles || 0,
            imageUrl: vehicle.odometer?.odometerUrl || '',
            uplodedDate: uploadDate,
          })
          break
        default:
          break
      }
    })
  )

  return {
    email: policy.email,
    userId: policy.userId,
    phoneNumber: policy.phoneNumber,
    state: policy.state,
    pendingApprovalOdometer: pendingApprovalOdometer,
    approvedOdometer: approvedOdometer,
  }
}

const mapUploadOdometerManagerToStoreObject = (
  policy: ApiPolicy,
  policyPriceBreakdown: ApiTransactions
): Partial<OdometerManager> => {
  const transactionWithOdometerPendingUpload = policyPriceBreakdown.filter(t => t.pendingUpload)

  const transactionsWithOdometerPendingOrderByDate = transactionWithOdometerPendingUpload.sort(
    (a, b) => new Date(a.effectiveDateUtc).getTime() - new Date(b.effectiveDateUtc).getTime()
  )

  const pendingTransaction = transactionsWithOdometerPendingOrderByDate
    ? transactionsWithOdometerPendingOrderByDate[0]
    : undefined

  const result = policy.vehicles.map(v => {
    const validatedValues = policyPriceBreakdown
      .flatMap(t => t.vehicles)
      .filter(tv => tv.id === v.id && tv.validateMiles !== undefined)
      .map(tv => tv.validateMiles as number)

    return {
      vehicleId: v.id,
      transactionItemId: pendingTransaction?.id,
      vehicle: `${v.year}, ${v.make}, ${v.model}`,
      lastValidationValue: validatedValues.length ? Math.max(...validatedValues) : undefined,
    }
  })
  return { odometerToUpload: result }
}

const dashboardMutations = createResourceMutations(initialState, (state: OdometerManagerResource) => ({
  setVehiclePenddingApproval: (pendingApprovalOdometer: PendingApprovalOdometer[]) => ({
    ...state,
    pendingApprovalOdometer,
  }),
  setStoreDefaultValue: () => ({ ...defaultState }),
}))

const fetchPolicyOdometerData = createResourceThunk(
  'odometerManager',
  async (policyId: Key): Promise<Partial<OdometerManagerResource>> => {
    const { data: policy } = await getPolicyByIdEndpoint(null, { pathParams: { policyId: policyId } })
    const { data: policyPriceBreakdown } = await getUserPolicyTransactionsEndpoint(null, {
      pathParams: { policyId: policyId },
    })
    const res = mapResourceOdometerManagerToStoreObject(policy.result, policyPriceBreakdown.result)
    const odometersToUpload = mapUploadOdometerManagerToStoreObject(policy.result, policyPriceBreakdown.result)
    return { ...res, odometerToUpload: odometersToUpload.odometerToUpload }
  }
)

const fetchPolicyOdometerToUpload = createResourceThunk(
  'odometerManager',
  async (policyId: Key): Promise<Partial<OdometerManagerResource>> => {
    const { data: policy } = await getPolicyByIdEndpoint(null, { pathParams: { policyId: policyId } })
    const { data: policyPriceBreakdown } = await getUserPolicyTransactionsEndpoint(null, {
      pathParams: { policyId: policyId },
    })
    const res = mapUploadOdometerManagerToStoreObject(policy.result, policyPriceBreakdown.result)
    return {
      ...res,
    }
  }
)

export default createStore(
  'odometerManager',
  initialState,
  dashboardMutations,
  { fetchPolicyOdometerData, fetchPolicyOdometerToUpload },
  composeMiddleware(consoleMiddleware, localStorageMiddleware)
)
